import { Suspense, useRef } from "react";
import { Canvas } from "@react-three/fiber";
import { VRButton, XR, Controllers, Hands } from "@react-three/xr";
import {
  Environment,
  OrbitControls,
  BakeShadows,
  Stats,
} from "@react-three/drei";
import { Leva } from "leva";
import DefaultHandControllers from "components/HandTracking/DefaultHandControllers";
import Court from "components/ThreeDimension/Court";
import Loader from "components/Loader";
import "global.css";
import Floor from "components/ThreeDimension/Floor";
import HouseModel from "components/ThreeDimension/HouseModel";
import { useSearchParams } from "react-router-dom";

export default function ThreeDimensionEnvironment() {
  const experiences = {
    basketballCourt: (
      <>
        <fog attach="fog" args={["purple", 0, 130]} />
        <Court />
        <Floor />
      </>
    ),
    house: <HouseModel />,
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get("type");

  return (
    <>
      {process.env.REACT_APP_DEBUG === "true" && (
        <>
          <Leva />
        </>
      )}

      <VRButton />
      <Canvas
        frameloop="demand"
        dpr={[1, 1.5]}
        shadows
        camera={{ near: 0.1, far: 2000, fov: 75 }}
      >
        <XR>
          <Suspense fallback={<Loader />}>
            <ambientLight intensity={0.5} />
            <group position={[0, -1, 0]}>
              {/* <spotLight
                castShadow
                intensity={10}
                angle={0.1}
                position={[-200, 220, -100]}
                shadow-mapSize={[2048, 2048]}
                shadow-bias={-0.000001}
              />
              <spotLight
                angle={0.1}
                position={[-250, 120, -200]}
                intensity={1}
                castShadow
                shadow-mapSize={[50, 50]}
                shadow-bias={-0.000001}
              />
              <spotLight
                angle={0.1}
                position={[250, 120, 200]}
                intensity={1}
                castShadow
                shadow-mapSize={[50, 50]}
                shadow-bias={-0.000001}
              /> */}

              {experiences[type]}
            </group>
            <OrbitControls
              minPolarAngle={Math.PI / 2}
              maxPolarAngle={Math.PI / 2}
            />
            <Environment
              files="https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/hdris/noon-grass/noon_grass_1k.hdr"
              background
              blur={0}
            />
            <BakeShadows />

            <Controllers />
            <Hands />

            {process.env.REACT_APP_DEBUG === "true" && (
              <>
                <Stats />
              </>
            )}

            <DefaultHandControllers />
          </Suspense>
        </XR>
      </Canvas>
    </>
  );
}
