export const pinchPose = {
  wrist: { position: [0, 0, 0], quaternion: [-0.6527377146928867, -0.04197306512862318, -0.14750412810399346, 0.7418990967870879] },
  'thumb-metacarpal': {
    position: [-0.028708115220069885, -0.03802686929702759, 0.01521763950586319],
    quaternion: [-0.5018827760314641, 0.7401433178094579, 0.26408695476159333, 0.361330359297011]
  },
  'thumb-phalanx-proximal': {
    position: [-0.03747981786727905, -0.06252908706665039, 0.03470578417181969],
    quaternion: [-0.5550449762706562, 0.6602166569844041, 0.29175448580945107, 0.41342273784482036]
  },
  'thumb-phalanx-distal': {
    position: [-0.0449826717376709, -0.09105652570724487, 0.05119417980313301],
    quaternion: [-0.47353951037964503, 0.7562219659595647, 0.22196308825783814, 0.39321884207232527]
  },
  'thumb-tip': {
    position: [-0.05551515519618988, -0.10836756229400635, 0.06518009305000305],
    quaternion: [-0.47353951037964503, 0.7562219659595647, 0.22196308825783814, 0.39321884207232527]
  },
  'index-finger-metacarpal': {
    position: [-0.02306044101715088, -0.03862184286117554, -0.0009634285233914852],
    quaternion: [-0.7085028717326327, 0.05255272987000617, -0.20691323120441896, 0.6726431491380622]
  },
  'index-finger-phalanx-proximal': {
    position: [-0.036951854825019836, -0.0910634994506836, -0.01286252960562706],
    quaternion: [-0.9520349028684798, -0.01887202901320798, -0.0894724011539764, 0.2920069856576565]
  },
  'index-finger-phalanx-intermediate': {
    position: [-0.04299519956111908, -0.11227923631668091, 0.017989562824368477],
    quaternion: [-0.9948080696577799, -0.018279256564374204, -0.09906207423043162, 0.014473381511597695]
  },
  'index-finger-phalanx-distal': {
    position: [-0.04777246713638306, -0.11306709051132202, 0.04180603101849556],
    quaternion: [-0.9966383793141129, -0.0348255636574372, -0.07342604494118556, 0.010379639607186953]
  },
  'index-finger-tip': {
    position: [-0.05066494643688202, -0.11464649438858032, 0.06395097076892853],
    quaternion: [-0.9966383793141129, -0.0348255636574372, -0.07342604494118556, 0.010379639607186953]
  },
  'middle-finger-metacarpal': {
    position: [-0.011009499430656433, -0.04054677486419678, 0.0022756149992346764],
    quaternion: [-0.728576836221424, -0.05452649690398996, -0.20251115292559713, 0.6520673951346632]
  },
  'middle-finger-phalanx-proximal': {
    position: [-0.014801859855651855, -0.0938032865524292, -0.011814166558906436],
    quaternion: [-0.9643559144702265, 0.002296662407013733, -0.14345090442071629, 0.22233810647066332]
  },
  'middle-finger-phalanx-intermediate': {
    position: [-0.02672255039215088, -0.11218321323394775, 0.025101996958255768],
    quaternion: [0.829592712909983, -0.09380243778711712, 0.1057267691567219, 0.5401841201252863]
  },
  'middle-finger-phalanx-distal': {
    position: [-0.02876339852809906, -0.08694499731063843, 0.03595779836177826],
    quaternion: [0.36512814687348283, -0.047265183334492235, 0.0021122995466034376, 0.9297542562396359]
  },
  'middle-finger-tip': {
    position: [-0.026344195008277893, -0.06916528940200806, 0.018560543656349182],
    quaternion: [0.36512814687348283, -0.047265183334492235, 0.0021122995466034376, 0.9297542562396359]
  },
  'ring-finger-metacarpal': {
    position: [0.0003604888916015625, -0.03952282667160034, 0.0056415945291519165],
    quaternion: [-0.7396808097017777, -0.16093438809609775, -0.1956158263891162, 0.6234636083631186]
  },
  'ring-finger-phalanx-proximal': {
    position: [0.0033015459775924683, -0.09054785966873169, -0.0021084961481392384],
    quaternion: [-0.9716843359574324, -0.05038339502943255, -0.17894891706806382, 0.14583672322305824]
  },
  'ring-finger-phalanx-intermediate': {
    position: [-0.00968681275844574, -0.10230308771133423, 0.03273133561015129],
    quaternion: [0.7759395377553959, -0.05574459995025362, 0.18814084493382177, 0.5995109638629774]
  },
  'ring-finger-phalanx-distal': {
    position: [-0.015669360756874084, -0.07702267169952393, 0.03832182288169861],
    quaternion: [0.32587486330368326, -0.10939771910707881, 0.1317591102661254, 0.9297726869417349]
  },
  'ring-finger-tip': {
    position: [-0.013075575232505798, -0.060324668884277344, 0.020747322589159012],
    quaternion: [0.32587486330368326, -0.10939771910707881, 0.1317591102661254, 0.9297726869417349]
  },
  'pinky-finger-metacarpal': {
    position: [0.01489970088005066, -0.038179636001586914, 0.00994948297739029],
    quaternion: [-0.6574740246753815, -0.27719103523257715, -0.2055514915428402, 0.6698071522373896]
  },
  'pinky-finger-phalanx-proximal': {
    position: [0.01950991153717041, -0.08358919620513916, 0.010780317708849907],
    quaternion: [-0.954898987936288, -0.09855212426684874, -0.26847438050731093, 0.07985554866106723]
  },
  'pinky-finger-phalanx-intermediate': {
    position: [0.0042420923709869385, -0.0898999571800232, 0.036680374294519424],
    quaternion: [0.7371802888124096, -0.028210442936276114, 0.26473157891790616, 0.6210367008635028]
  },
  'pinky-finger-phalanx-distal': {
    position: [-0.0029739439487457275, -0.07099878787994385, 0.03847714141011238],
    quaternion: [0.38578432275097324, -0.11353125177035334, 0.2603789668506576, 0.8777721257893837]
  },
  'pinky-finger-tip': {
    position: [-0.0038779377937316895, -0.05425739288330078, 0.024298351258039474],
    quaternion: [0.38578432275097324, -0.11353125177035334, 0.2603789668506576, 0.8777721257893837]
  }
}
