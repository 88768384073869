import { useState } from "react";
import { useFrame } from "@react-three/fiber";
import { Text } from "@react-three/drei";
import usePlayerWithDev from "hooks/usePlayerWithDev";

export default function FPSCounter() {
  const [fps, setFps] = useState(0);
  let lastFrameTime = performance.now();
  const { player } = usePlayerWithDev();

  useFrame(() => {
    const now = performance.now();
    const deltaTime = now - lastFrameTime;
    lastFrameTime = now;
    const newFps = 1 / (deltaTime / 1000);
    setFps(newFps);
  });

  const position = [
    player.position.x,
    player.position.y + 2,
    player.position.z - 5,
  ];

  return (
    <Text position={position as any} fontSize={0.5} color="white">
      {`FPS: ${fps.toFixed(2)}`}
    </Text>
  );
}
