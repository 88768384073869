import { setAutoFreeze, produce } from "immer";
import { MutableRefObject } from "react";
import { Object3D } from "three";
import createStore, { State as ZustandState, StateCreator } from "zustand";
import { XRHandedness } from "utils/types";
import { HandModel } from "components/HandTracking/HandModel";

const immer =
  <T extends ZustandState>(config: any): StateCreator<T> =>
  (set, get, api) =>
    config((fn) => set(produce(fn) as (state: T) => T), get, api);

setAutoFreeze(false);

export type State = {
  hands: {
    models?: MutableRefObject<{ [key in XRHandedness]?: HandModel }>;
    interacting?: MutableRefObject<{ [key in XRHandedness]?: Object3D }>;
  };
  isMoveGestureDetected: boolean;
  isMovementRestricted: boolean;
  set: (fn: (state: State) => void | State) => void;
};

export const useStore = createStore<State>(
  immer((set, get, api) => {
    return {
      hands: {
        models: undefined,
        interacting: undefined,
      },
      isMoveGestureDetected: false,
      isMovementRestricted: false,
      set: (fn: (state: State) => State) => {
        set(fn);
      },
    };
  })
);
