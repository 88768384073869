import { useRef, useEffect, useState } from "react";
import { Canvas, useLoader } from "@react-three/fiber";
import { useXR, VRButton, XR } from "@react-three/xr";
import * as THREE from "three";
import { TextureLoader } from "three";
import { OrbitControls } from "@react-three/drei";

// Custom shader material
const PanoramaShader = {
  uniforms: {
    texture1: { value: null },
    depthMap: { value: null },
    displacementScale: { value: -4.0 }
  },
  vertexShader: `
    varying vec2 vUv;
    varying float vDisplacement;
    uniform sampler2D depthMap;
    uniform float displacementScale;

    void main() {
      vUv = uv;
      vec4 depthData = texture2D(depthMap, uv);
      vDisplacement = depthData.r * displacementScale;
      
      vec3 transformedPosition = position + normal * vDisplacement;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(transformedPosition, 1.0);
    }
  `,
  fragmentShader: `
    uniform sampler2D texture1;
    varying vec2 vUv;
    varying float vDisplacement;

    void main() {
      vec4 color = texture2D(texture1, vUv);
      gl_FragColor = color;
    }
  `,
};

function Panorama() {
  const sphereRef = useRef<any>();
  const { isPresenting } = useXR();
  const [texturesLoaded, setTexturesLoaded] = useState(false);

  const [texture, depth] = useLoader(TextureLoader, [
    // "/textures/kandao3.jpg",
    // "/textures/kandao3_depthmap.jpg",

    "https://truevue.s3.us-west-1.amazonaws.com/0001-2024-10-29T15-10-25-511Z.jpg",
    "https://truevue.s3.us-west-1.amazonaws.com/depthmap-0001-2024-10-29T15-10-25-511Z.jpg"
  ]);

  useEffect(() => {
    if (texture && depth) {
      setTexturesLoaded(true);
      texture.minFilter = THREE.NearestFilter;
      texture.generateMipmaps = false;

      depth.minFilter = THREE.NearestFilter;
      depth.generateMipmaps = false;
    }
  }, [texture, depth]);

  if (!texturesLoaded) return null;

  return (
    <mesh ref={sphereRef} position={[0, isPresenting ? 2.6 : 0, 0]}>
      <sphereGeometry args={[6, 250, 256]} />
      <shaderMaterial
        args={[{
          uniforms: {
            texture1: { value: texture },
            depthMap: { value: depth },
            displacementScale: { value: -4.0 },
          },
          vertexShader: PanoramaShader.vertexShader,
          fragmentShader: PanoramaShader.fragmentShader,
          side: THREE.BackSide,
        }]}
      />
    </mesh>
  );
}

export default function App() {
  return (
    <>
      <VRButton />
      <Canvas>
        <XR referenceSpace="local-floor">
          <ambientLight intensity={10} />

          <spotLight
            position={[0, 20, 10]}
            angle={0.3}
            penumbra={0.5}
            intensity={2}
            castShadow
          />

          <Panorama />
          <OrbitControls />
        </XR>
      </Canvas>
    </>
  );
}
