import { createBrowserRouter } from "react-router-dom";
import Home from "pages/Home";
import StereoscopicView from "pages/StereoscopicView";
import "./global.css";
import ThreeDimensionEnvironment from "pages/ThreeDimensionEnvironment";
import Development from "pages/Development";
import DepthMap from "pages/DepthMap";
import Development2 from "pages/Development2";
import Development3 from "pages/Development3";
import Development4 from "pages/Development4";
import DepthMapStreetView from "pages/DepthMapStreetView";
import DevelopmentHome from "pages/DevelopmentHome";
import PropertyExperience from "pages/PropertyExperience";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/property-experience/:propertyId",
    element: <PropertyExperience />,
  },
  {
    path: "/dev-home",
    element: <DevelopmentHome />,
  },
  {
    path: "/development",
    element: <Development />,
  },
  {
    path: "/development2",
    element: <Development2 />,
  },
  {
    path: "/development3",
    element: <Development3 />,
  },
  {
    path: "/development4",
    element: <Development4 />,
  },
  {
    path: "/depthmap-street-view",
    element: <DepthMapStreetView />,
  },
  {
    path: "/stereoscopic-view",
    element: <StereoscopicView />,
  },
  {
    path: "/3d-model-environment",
    element: <ThreeDimensionEnvironment />,
  },
  {
    path: "/depth-map",
    element: <DepthMap />,
  },
]);

export default router;
