import { useParams } from "react-router-dom";
import Experience from "components/Experience";
import useSingleProperty from "hooks/useSingleProperty";
import LoadingSpinner from "components/LoadingSpinner";

export default function PropertyExperience() {
  const { propertyId } = useParams<{ propertyId: string }>();
  const { property } = useSingleProperty(propertyId);

  return (
    <>
      {
        property && propertyId ? (
            <Experience propertyId={propertyId} property={property} />
        ) : (
            <div className="w-screen h-screen bg-blue-950 flex flex-col justify-center items-center gap-12">
            <LoadingSpinner />
          </div>
        )
      }
    </>
  );
}
