import { useGLTF } from "@react-three/drei";
import { useRef } from "react";
import { useControls } from 'leva';

function HouseModel() {
    const { scene } = useGLTF('/models/House-compressed.glb');
    const ref = useRef();
    
    const { position, scale } = useControls({
        position: { x: -66, y: 8, z: -5 },
        scale: { x: 120, y: 120, z: 120 }
    });

    return (
        <>
            <primitive 
                ref={ref} 
                object={scene} 
                position={[position.x, position.y, position.z]} 
                scale={[scale.x, scale.y, scale.z]} 
            />
        </>
    );
}

export default HouseModel;
