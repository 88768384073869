import React from 'react';
import { Canvas } from '@react-three/fiber';
import { ShaderMaterial } from 'three';
import { useRef } from 'react';
import { OrbitControls } from '@react-three/drei';

function SphereWithShader() {
  const sphereRef = useRef();

  const vertexShader = `
    varying vec3 vPosition;
    void main() {
      vPosition = position;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `;

  const fragmentShader = `
    varying vec3 vPosition;
    void main() {
      vec3 color = vec3(0.0, 0.0, 1.0); // Base color: blue
      if(vPosition.y > 0.5) {
        color = vec3(1.0, 0.0, 0.0); // Red color at the top
      }
      gl_FragColor = vec4(color, 1.0);
    }
  `;

  const shaderMaterial = new ShaderMaterial({
    vertexShader,
    fragmentShader,
  });

  return (
    <mesh ref={sphereRef} material={shaderMaterial}>
      <sphereGeometry args={[1, 64, 64]} />
    </mesh>
  );
}

function App() {
  return (
    <Canvas>
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <SphereWithShader />
      <OrbitControls />
    </Canvas>
  );
}

export default App;
