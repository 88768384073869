import { useState, useEffect } from "react";
import PropertyService from "services/property.service";
import { Property } from "types";

export default function useAllProperties() {
  const propertyService = new PropertyService();

  const [properties, setProperties] = useState<Property[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchProperties = async () => {
    try {
      const data: Property[] | any = await propertyService.fetchProperties();

      if (data?.statusCode === 400) {
        throw new Error(data?.message);
      }

      setProperties(data);
    } catch (error) {
      setError("Error fetching properties");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProperties();
  }, []);

  return {
    properties,
    loading,
    error,
  };
}
