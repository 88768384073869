import { useEffect } from "react";
import { Html, useProgress } from "@react-three/drei";

function Loader() {
  const { active, progress, errors, item, loaded, total } = useProgress();

  // useEffect(() => {
  //   console.log("progress: ", progress);
  //   console.log("active: ", active);
  //   console.log("loaded: ", loaded);
  //   console.log("item: ", item);
  // }, [progress, active, loaded, item]);

  return (
    <Html style={{ background: "black", color: "white" }} center>
      {progress} % loaded
    </Html>
  );
}

export default Loader;
