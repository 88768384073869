import { useRef } from 'react';
import { Canvas, useLoader } from '@react-three/fiber';
import { ShaderMaterial, TextureLoader } from 'three';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';

function SphereWithShader() {
  const sphereRef = useRef();

  const lowResTexture = useLoader(TextureLoader, '/images/output_image_480p.png');
  const highResTexture = useLoader(TextureLoader, '/images/output_frame_8K.jpg');

  const vertexShader = `
    varying vec3 vPosition;
    varying vec2 vUv;
    void main() {
      vPosition = position;
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `;

  const fragmentShader = `
    varying vec3 vPosition;
    varying vec2 vUv;
    uniform sampler2D uLowResTexture;
    uniform sampler2D uHighResTexture;
    
    void main() {
      vec3 color = texture2D(uLowResTexture, vUv).rgb; // Default to low-res texture

      // Draw a red line at the boundary
      if (abs(vPosition.x) < 0.01) {
        // Adjust the thickness of the line with this value

        color = vec3(1.0, 0.0, 0.0); // Red color for the boundary
      } else if (vPosition.x < 0.0) {
        // Apply high-res texture to the left half

        color = texture2D(uHighResTexture, vUv).rgb;
      }

      gl_FragColor = vec4(color, 1.0);
    }
  `;

  const shaderMaterial = new ShaderMaterial({
    vertexShader,
    fragmentShader,
    uniforms: {
      uLowResTexture: { value: lowResTexture },
      uHighResTexture: { value: highResTexture },
    },
    side: THREE.BackSide,
  });

  return (
    <mesh ref={sphereRef} material={shaderMaterial}>
      <sphereGeometry args={[1, 64, 64]} />
    </mesh>
  );
}

function App() {
  return (
    <Canvas>
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <SphereWithShader />
      <OrbitControls />
    </Canvas>
  );
}

export default App;
