import { useGLTF } from "@react-three/drei";
import { applyProps } from "@react-three/fiber";
import { useLayoutEffect } from "react";

function Court(props) {
  const { scene } = useGLTF("/models/court.glb");

  useLayoutEffect(() => {
    scene.traverse((o: any) => {
      if (o.isMesh) {
        applyProps(o, {
          castShadow: true,
          receiveShadow: true,
          "material-envMapIntensity": 0.1,
        });
      }
    });
    const floor = scene.getObjectByName("GymFloor_ParquetShader_0");
    if (floor) floor.parent.remove(floor);
  }, [scene]);

  return <primitive object={scene} {...props} />;
}

export default Court;
