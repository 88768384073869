import { BufferGeometry, DoubleSide, Material, Mesh, MeshBasicMaterial, NormalBufferAttributes, Object3DEventMap, Shape, ShapeGeometry, Vector2 } from "three";
import React from "react";

type Props = {
  name: string;
  position: [number, number, number];
  roomRef: React.Ref<Mesh<BufferGeometry<NormalBufferAttributes>, Material | Material[], Object3DEventMap>>;
  points: [number, number][];
  color?: string;
}

const RoomFloor = ({ name, roomRef, position, points, color = "#9fd3ff" }: Props) => {
  const shape = new Shape();
  if (points.length > 0) {
    shape.moveTo(points[0][0], points[0][1]);
    for (let i = 1; i < points.length; i++) {
      shape.lineTo(points[i][0], points[i][1]);
    }
    shape.closePath();
  }

  const geometry = new ShapeGeometry(shape);
  const material = new MeshBasicMaterial({
    color,
    side: DoubleSide,
    wireframe: true,
  });

  return (
    <mesh
      ref={roomRef}
      name={name}
      position={position}
      geometry={geometry}
      material={material}
      rotation={[-Math.PI / 2, 0, 0]}
    />
  );
};

export default RoomFloor;
