import React from "react";
import {
  BufferGeometry,
  DoubleSide,
  Material,
  Mesh,
  MeshBasicMaterial,
  NormalBufferAttributes,
  Object3DEventMap,
  Shape,
  ShapeGeometry,
} from "three";

type OpeningProps = {
  name: string;
  openingRef: React.Ref<Mesh<BufferGeometry<NormalBufferAttributes>, Material | Material[], Object3DEventMap>>;
  coordinates: [number, number][];
  color?: string;
  position: [number, number, number];
};

const Opening = ({
  name,
  openingRef,
  coordinates,
  color = "#7b4223",
  position,
}: OpeningProps) => {
  const shape = new Shape();

  if (coordinates.length > 0) {
    shape.moveTo(coordinates[0][0], coordinates[0][1]);
    for (let i = 1; i < coordinates.length; i++) {
      shape.lineTo(coordinates[i][0], coordinates[i][1]);
    }
    shape.closePath();
  }

  const geometry = new ShapeGeometry(shape);
  const material = new MeshBasicMaterial({
    color,
    side: DoubleSide,
    wireframe: true,
  });

  return (
    <mesh
      ref={openingRef}
      name={name}
      position={position}
      geometry={geometry}
      material={material}
    />
  );
};

export default Opening;
