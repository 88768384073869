import { useRef, useEffect } from "react";
import { Canvas, useThree } from "@react-three/fiber";
import { BoxHelper } from "three";
import { OrbitControls } from "@react-three/drei";

function BoundingBox({ positions }) {
    const boxRef = useRef();
    const { scene } = useThree();

    useEffect(() => {
        const boxHelper = new BoxHelper(boxRef.current, 0xff0000);
        scene.add(boxHelper);

        return () => {
            scene.remove(boxHelper);
        };
    }, [scene]);

    return (
        <group ref={boxRef}>
            {positions.map((pos, index) => (
                <mesh position={pos} key={index}>
                    <sphereGeometry args={[0.5, 16, 16]} />
                    <meshBasicMaterial color="blue" />
                </mesh>
            ))}
        </group>
    );
}

export default function App() {
    const positions = [
        [10, 0, 10],
        [20, 0, 10],
        [30, 0, 10]
    ];

    return (
        <Canvas style={{ backgroundColor: 'black' }}>
            <OrbitControls />
            <ambientLight />
            <BoundingBox positions={positions} />
        </Canvas>
    );
}
