import { Property } from "types";


class PropertyService {
  private baseUrl: string;

  constructor() {
    this.baseUrl = `${process.env.REACT_APP_BACKEND_URL}/property`;
  }

  async fetchProperties(): Promise<Property[]> {
    try {
      const response = await fetch(`${this.baseUrl}/get-all-properties`);
      const data: Property[] = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching properties:", error);
      throw error;
    }
  }

  async fetchSingleProperty(propertId: string): Promise<any> {
    try {
      const response = await fetch(`${this.baseUrl}/get-property-by-id/${propertId}`);
      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching properties:", error);
      throw error;
    }
  }
}

export default PropertyService;
