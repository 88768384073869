import { useXR } from "@react-three/xr";
import * as THREE from "three";

export default function usePlayerWithDev() {
  const { player, isPresenting } = useXR();

  const playerDevInstance = new THREE.Object3D();
  playerDevInstance.position.set(40, 0, 20); // infront of opening
  //   playerDevInstance.position.set(40, 0, 10); // infront of wall

  return {
    player: isPresenting ? player : playerDevInstance,
  };
}
