import { Link } from "react-router-dom";
import { cn } from "utils/helper";

export default function DevelopmentHome() {
  const links = [
    {
      label: "3D House Experience",
      link: "/3d-model-environment?type=house",
    },
    {
      label: "Depth Map",
      link: "/depth-map",
    },
    {
      label: "Development",
      link: "/development",
    },
    {
      label: "Development 2",
      link: "/development2",
    },
    {
      label: "Development 4",
      link: "/development4",
    },
    {
      label: "Depth Map Street View",
      link: "/depthmap-street-view",
    },
    {
      label: "Office Experience",
      link: "/experience?type=office",
    },
    {
      label: "3D Basketball Court Experience",
      link: "/3d-model-environment?type=basketballCourt",
    },
    // {
    //   label: "Backyard Experience",
    //   link: "/experience?type=backyard",
    // },
    {
      label: "Stereoscopic View (Experimental)",
      link: "/stereoscopic-view",
    },
  ];

  return (
    <div className="w-screen h-screen bg-blue-950 flex flex-col justify-center items-center gap-12  ">
      <h1 className="text-5xl font-extrabold text-white mb-10">TrueVue Reality</h1>

      <div className="flex flex-wrap justify-center gap-4">
        {links.map(({ link, label }, index) => (
          <Link
            to={link}
            key={index}
            className={cn(
              "flex justify-center items-center px-4",
              "min-w-64 min-h-48 text-teal-200 font-bold text-2xl rounded-md",
              "border-2 border-teal-200",
              "hover:bg-teal-200 hover:text-blue-950"
            )}
          >
            <span>{label}</span>
          </Link>
        ))}
      </div>
    </div>
  );
}
