import * as THREE from "three";

export default function useCommonMethods() {
  const arrayToVector3 = (array: number[]) => {
    return new THREE.Vector3(array[0], array[1], array[2]);
  };

  return {
    arrayToVector3,
  };
}
