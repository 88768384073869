import { useRef, useEffect } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { XRController, useXR } from "@react-three/xr";
import * as THREE from "three";
import { HandModel } from "components/HandTracking/HandModel";

interface Props {
  controller: XRController;
  model: HandModel;
}

export default function HandDirectionHelper({ model }: Props) {
  const { scene } = useThree();
  const { isHandTracking } = useXR();
  const helpersRef = useRef<
    Map<string, { line: THREE.Line; axesHelper: THREE.AxesHelper }>
  >(new Map());
  const palmDirArrowHelper = useRef<THREE.ArrowHelper>(null);

  useEffect(() => {
    const arrow = new THREE.ArrowHelper(
      new THREE.Vector3(1, 0, 0),
      new THREE.Vector3(0, 0, 0),
      0.1,
      0xffff00
    );
    scene.add(arrow);
    palmDirArrowHelper.current = arrow;

    return () => {
      scene.remove(arrow);
    };
  }, [scene]);

  useEffect(() => {
    return () => {
      helpersRef.current.forEach(({ line, axesHelper }) => {
        scene.remove(line);
        scene.remove(axesHelper);
      });
      helpersRef.current.clear();
    };
  }, [scene]);

  useFrame((state, delta) => {
    if (!isHandTracking) return;

    if (!model || model.bones.length === 0) {
      return;
    }

    const wrist = model.bones.find(
      (bone) => (bone as any).jointName === "wrist"
    );
    if (wrist) {
      drawDirectionHelper(wrist);

      const { position, direction } =
        getLinePositionAndDirectionFromBone(wrist);

      const directionVector = new THREE.Vector3(
        direction.x,
        direction.y,
        direction.z
      )
        .normalize()
        .negate();

      directionVector.y = 0;

      const positionVector = new THREE.Vector3(
        position.x,
        position.y,
        position.z
      );

      if (palmDirArrowHelper.current) {
        palmDirArrowHelper.current.position.copy(positionVector);
        palmDirArrowHelper.current.setDirection(directionVector);
      }
    }
  });

  function getLinePositionAndDirectionFromBone(bone: THREE.Object3D) {
    const localDirection = new THREE.Vector3(0, 1, 0).normalize();

    const position = new THREE.Vector3();
    bone.getWorldPosition(position);

    const worldQuaternion = new THREE.Quaternion();
    bone.getWorldQuaternion(worldQuaternion);

    const direction = localDirection.applyQuaternion(worldQuaternion);

    return { position, direction };
  }

  const drawDirectionHelper = (bone: THREE.Object3D) => {
    const jointName = (bone as any).jointName;
    if (!jointName) return;

    let { line, axesHelper } = helpersRef.current.get(jointName) || {};

    if (!line || !axesHelper) {
      const material = new THREE.LineBasicMaterial({
        color: "yellow",
        linewidth: 5,
      });
      const points = [
        new THREE.Vector3(),
        new THREE.Vector3(0, 1, 0).normalize().multiplyScalar(-20),
      ];
      const geometry = new THREE.BufferGeometry().setFromPoints(points);

      line = new THREE.Line(geometry, material);
      axesHelper = new THREE.AxesHelper(0.2);

      bone.add(line);
      bone.add(axesHelper);

      helpersRef.current.set(jointName, { line, axesHelper });
    }
  };

  return null;
}
