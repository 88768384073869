import { useStore } from "utils/store";
import * as THREE from 'three';
import { MAX_PLAYER_REGION_RADIUS, MAX_VALID_DIST_FROM_PLAYER_TO_DATAPOINT } from "config/constants";
import usePlayerWithDev from "./usePlayerWithDev";

export default function useMovementHelper() {
  const isMovementRestricted = useStore((store) => store.isMovementRestricted);
  const set = useStore((state) => state.set);
  const { player } = usePlayerWithDev();

  const toggleMovementRestriction = () => {
    set((state: any) => {
        state.isMoveGestureDetected = !isMovementRestricted;
      });
  }

  const calculateDataPointDistanceFromPlayerCenter = (dataPointPosition: number[]) => {
    const distance = player.position.distanceTo(
        new THREE.Vector3(
          dataPointPosition[0],
          dataPointPosition[1],
          dataPointPosition[2]
        )
      );

      return distance;
  }
  
  const checkIfPlayerIsCloseEnoughToDataPointe = (distance: number) => distance < MAX_VALID_DIST_FROM_PLAYER_TO_DATAPOINT;

  const checkIsDataPointViewable = (dataPointPosition: number[]) => {
    // if (currentSelectedDataPointIndex !== dataPointIndex) {
    //     return false;
    // }
    let isViewable = null;

    const distance = calculateDataPointDistanceFromPlayerCenter(dataPointPosition)

    if (distance <= MAX_PLAYER_REGION_RADIUS) {
        isViewable = true;
    } else {
        isViewable = false;
    }

    return { distance, isViewable }
  }

  return {
    isMovementRestricted,
    toggleMovementRestriction,
    calculateDataPointDistanceFromPlayerCenter,
    checkIfPlayerIsCloseEnoughToDataPointe,
    checkIsDataPointViewable,
  }
}
