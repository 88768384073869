import { useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';

interface ArrowHelperProps {
  arrowRef: React.MutableRefObject<THREE.ArrowHelper | null>;
  direction: THREE.Vector3;
  origin: THREE.Vector3;
  length: number;
  color?: string;
}

function ArrowHelper({
  arrowRef,
  direction,
  origin,
  length,
  color = 'white',
}: ArrowHelperProps) {
  const { scene } = useThree();

  useEffect(() => {
    // Create the ArrowHelper with the provided parameters
    const arrowHelper = new THREE.ArrowHelper(direction.normalize(), origin, length, new THREE.Color(color));
    
    // Assign the arrowHelper to the ref
    arrowRef.current = arrowHelper;

    // Add the arrowHelper to the scene
    scene.add(arrowHelper);

    // Clean up by removing the arrowHelper from the scene when the component is unmounted
    return () => {
      scene.remove(arrowHelper);
    };
  }, [direction, origin, length, color, scene]);

  return null;
}

export default ArrowHelper;
