export const defaultPose = {
  wrist: {
    position: [1.8626447606528984e-10, -8.940696516468449e-10, 5.96046212386625e-10],
    quaternion: [-0.7071068286895752, 5.760118781950041e-8, 5.760119137221409e-8, 0.7071068286895752]
  },
  'thumb-metacarpal': {
    position: [-0.028028501197695732, -0.0359584279358387, 0.019157717004418373],
    quaternion: [-0.4763745069503784, 0.7100346684455872, 0.18028733134269714, 0.48622485995292664]
  },
  'thumb-phalanx-proximal': {
    position: [-0.044955722987651825, -0.05921865254640579, 0.03430764749646187],
    quaternion: [-0.47673991322517395, 0.6406403183937073, 0.11477915197610855, 0.5908678770065308]
  },
  'thumb-phalanx-distal': {
    position: [-0.06691253185272217, -0.08312328159809113, 0.0437118336558342],
    quaternion: [-0.39142173528671265, 0.7194157242774963, 0.09644205868244171, 0.5656226277351379]
  },
  'thumb-tip': {
    position: [-0.07789862900972366, -0.09167803823947906, 0.04891208931803703],
    quaternion: [-0.3947904706001282, 0.7221444249153137, 0.09097205102443695, 0.5606889724731445]
  },
  'index-finger-metacarpal': {
    position: [-0.015884488821029663, -0.029212743043899536, 0.009419834241271019],
    quaternion: [-0.77494215965271, 0.05332411825656891, -0.03195803984999657, 0.6289674043655396]
  },
  'index-finger-phalanx-proximal': {
    position: [-0.023550676181912422, -0.08854873478412628, 0.007316457573324442],
    quaternion: [-0.7392873764038086, 0.01683184690773487, 0.028036130592226982, 0.6725956797599792]
  },
  'index-finger-phalanx-intermediate': {
    position: [-0.022021925076842308, -0.13375452160835266, 0.01055047009140253],
    quaternion: [-0.7388502955436707, -0.01528934109956026, 0.020967017859220505, 0.673369824886322]
  },
  'index-finger-phalanx-distal': {
    position: [-0.02069162018597126, -0.15792004764080048, 0.01276903785765171],
    quaternion: [-0.686407744884491, -0.04156109690666199, 0.031442154198884964, 0.7253471612930298]
  },
  'index-finger-tip': {
    position: [-0.019425027072429657, -0.16941747069358826, 0.012159150093793869],
    quaternion: [-0.6862807869911194, -0.049977876245975494, 0.03852125257253647, 0.7245944738388062]
  },
  'middle-finger-metacarpal': {
    position: [-0.0032591435592621565, -0.029106352478265762, 0.009419835172593594],
    quaternion: [-0.7821785807609558, -0.023043932393193245, -0.06304748356342316, 0.6194276809692383]
  },
  'middle-finger-phalanx-proximal': {
    position: [-0.001725903362967074, -0.09164661914110184, 0.002543156733736396],
    quaternion: [-0.7427287697792053, -0.04151846468448639, 0.028317611664533615, 0.6677038669586182]
  },
  'middle-finger-phalanx-intermediate': {
    position: [0.002640301128849387, -0.13811546564102173, 0.007021433673799038],
    quaternion: [-0.7380437850952148, -0.06258413195610046, 0.036574337631464005, 0.6708479523658752]
  },
  'middle-finger-phalanx-distal': {
    position: [0.005685935262590647, -0.1653556078672409, 0.009792773053050041],
    quaternion: [-0.6704923510551453, -0.0809938833117485, 0.01419301237910986, 0.7373456954956055]
  },
  'middle-finger-tip': {
    position: [0.007302379701286554, -0.17740768194198608, 0.008763404563069344],
    quaternion: [-0.6713148355484009, -0.07821036130189896, 0.008088618516921997, 0.7369899153709412]
  },
  'ring-finger-metacarpal': {
    position: [0.008265813812613487, -0.026990527287125587, 0.009419836103916168],
    quaternion: [-0.779512882232666, -0.12313608825206757, -0.04890383780002594, 0.6122137308120728]
  },
  'ring-finger-phalanx-proximal': {
    position: [0.017465244978666306, -0.0846937820315361, 0.006529302801936865],
    quaternion: [-0.7372061014175415, -0.11990050226449966, 0.046976324170827866, 0.6632829904556274]
  },
  'ring-finger-phalanx-intermediate': {
    position: [0.02676110528409481, -0.1263144463300705, 0.010884810239076614],
    quaternion: [-0.7334646582603455, -0.1584361046552658, 0.03868652135133743, 0.6598719954490662]
  },
  'ring-finger-phalanx-distal': {
    position: [0.03319219499826431, -0.15188787877559662, 0.01416861079633236],
    quaternion: [-0.7179667949676514, -0.13197831809520721, 0.006449223030358553, 0.683420717716217]
  },
  'ring-finger-tip': {
    position: [0.03552267327904701, -0.16357211768627167, 0.014942227862775326],
    quaternion: [-0.7187318205833435, -0.13417485356330872, 0.003463511122390628, 0.6822094321250916]
  },
  'pinky-finger-metacarpal': {
    position: [0.022998575121164322, -0.034073565155267715, 0.009419831447303295],
    quaternion: [-0.6950896382331848, -0.24571159482002258, -0.052773747593164444, 0.6735659837722778]
  },
  'pinky-finger-phalanx-proximal': {
    position: [0.03505408763885498, -0.07389584183692932, 0.013691178523004055],
    quaternion: [-0.7371914982795715, -0.16642968356609344, -0.004589088726788759, 0.6548503041267395]
  },
  'pinky-finger-phalanx-intermediate': {
    position: [0.042367469519376755, -0.10740291327238083, 0.01811724156141281],
    quaternion: [-0.7181382179260254, -0.22876721620559692, 0.011543449014425278, 0.6571223735809326]
  },
  'pinky-finger-phalanx-distal': {
    position: [0.04895488917827606, -0.12640321254730225, 0.0209718719124794],
    quaternion: [-0.7028482556343079, -0.1945386528968811, -0.017829783260822296, 0.6839891672134399]
  },
  'pinky-finger-tip': {
    position: [0.05193105340003967, -0.13790695369243622, 0.02176165021955967],
    quaternion: [-0.7031674981117249, -0.2156880795955658, 0.0012595904991030693, 0.6775193810462952]
  }
}
