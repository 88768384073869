export const idlePose = {
  wrist: { position: [0, 0, 0], quaternion: [-0.6661913377195962, -0.01640709815701443, -0.11120849576590103, 0.7372601841595182] },
  'thumb-metacarpal': {
    position: [-0.03472973685711622, -0.03378564119338989, 0.010930486023426056],
    quaternion: [-0.4370977050405571, 0.801618309803501, 0.07092227508523968, 0.40165123245521117]
  },
  'thumb-phalanx-proximal': {
    position: [-0.053650377318263054, -0.048898518085479736, 0.03262612223625183],
    quaternion: [-0.5233785648562362, 0.7282073040614634, 0.1202576486209243, 0.4258251966578869]
  },
  'thumb-phalanx-distal': {
    position: [-0.07035422325134277, -0.06988000869750977, 0.05318659543991089],
    quaternion: [-0.4581473272467597, 0.7965817964345437, 0.10612693530136055, 0.37986253004120674]
  },
  'thumb-tip': {
    position: [-0.0838727355003357, -0.08245950937271118, 0.0694720670580864],
    quaternion: [-0.4581473272467597, 0.7965817964345437, 0.10612693530136055, 0.37986253004120674]
  },
  'index-finger-metacarpal': {
    position: [-0.022320279851555824, -0.03902029991149902, 0.001892339438199997],
    quaternion: [-0.7246411098667119, 0.07379692097282581, -0.16971426364871023, 0.6638119802024115]
  },
  'index-finger-phalanx-proximal': {
    position: [-0.03621675749309361, -0.09194290637969971, -0.0076351650059223175],
    quaternion: [-0.7178758851519482, 0.014103382112460663, -0.09023996284710636, 0.6901536475565782]
  },
  'index-finger-phalanx-intermediate': {
    position: [-0.04186903452500701, -0.12942814826965332, -0.0064560286700725555],
    quaternion: [-0.7210158724051519, -0.009055942274381036, -0.10289564726845996, 0.6851763184922297]
  },
  'index-finger-phalanx-distal': {
    position: [-0.04517357889562845, -0.15348654985427856, -0.0054865144193172455],
    quaternion: [-0.7015695377374556, -0.042174841107665895, -0.09454715252033762, 0.7050406388618262]
  },
  'index-finger-tip': {
    position: [-0.04632382467389107, -0.17581260204315186, -0.006704933941364288],
    quaternion: [-0.7015695377374556, -0.042174841107665895, -0.09454715252033762, 0.7050406388618262]
  },
  'middle-finger-metacarpal': {
    position: [-0.009995488449931145, -0.04066729545593262, 0.004085216671228409],
    quaternion: [-0.7433134312652817, -0.03421360938765304, -0.16345737120332623, 0.6477625024903804]
  },
  'middle-finger-phalanx-proximal': {
    position: [-0.014013143256306648, -0.09428155422210693, -0.008505694568157196],
    quaternion: [-0.9808402755032382, -0.015176837171140472, -0.09547985644838834, 0.1691319442825862]
  },
  'middle-finger-phalanx-intermediate': {
    position: [-0.021833037957549095, -0.10864841938018799, 0.031182728707790375],
    quaternion: [0.7920805673660862, -0.04819466602845819, 0.08346967527489871, 0.6027590416384704]
  },
  'middle-finger-phalanx-distal': {
    position: [-0.0238752830773592, -0.08212053775787354, 0.03832988440990448],
    quaternion: [0.2946729689061379, 0.0006527793449358052, 0.0037696326864468167, 0.955590500760958]
  },
  'middle-finger-tip': {
    position: [-0.02366100437939167, -0.06711900234222412, 0.018341291695833206],
    quaternion: [0.2946729689061379, 0.0006527793449358052, 0.0037696326864468167, 0.955590500760958]
  },
  'ring-finger-metacarpal': {
    position: [0.001606617122888565, -0.03937530517578125, 0.006404057145118713],
    quaternion: [-0.7528343283461107, -0.1418028902789975, -0.15518802882148325, 0.6237379979468028]
  },
  'ring-finger-phalanx-proximal': {
    position: [0.004814211279153824, -0.09050309658050537, -0.0005191080272197723],
    quaternion: [-0.9866094475388417, -0.05959877380566362, -0.13640739820695835, 0.06665437646825109]
  },
  'ring-finger-phalanx-intermediate': {
    position: [-0.0053722187876701355, -0.09626609086990356, 0.03667929023504257],
    quaternion: [0.6924812721369185, -0.02458229946205448, 0.16012588303971034, 0.7030114507414129]
  },
  'ring-finger-phalanx-distal': {
    position: [-0.010346893221139908, -0.07018381357192993, 0.03562350571155548],
    quaternion: [0.35032013919195115, -0.05025873404845777, 0.14068244212261186, 0.9246395569122036]
  },
  'ring-finger-tip': {
    position: [-0.010712355375289917, -0.05287259817123413, 0.018459565937519073],
    quaternion: [0.35032013919195115, -0.05025873404845777, 0.14068244212261186, 0.9246395569122036]
  },
  'pinky-finger-metacarpal': {
    position: [0.016442324966192245, -0.03768932819366455, 0.00937221571803093],
    quaternion: [-0.6706119869110772, -0.2552411067241566, -0.1670991033321349, 0.6761726333673979]
  },
  'pinky-finger-phalanx-proximal': {
    position: [0.02196628227829933, -0.08298414945602417, 0.010729294270277023],
    quaternion: [0.9705382983826922, 0.055846077967221575, 0.23396139656455833, 0.014095810216820616]
  },
  'pinky-finger-phalanx-intermediate': {
    position: [0.007966633886098862, -0.08294636011123657, 0.03807436674833298],
    quaternion: [0.6245149964487291, -0.04919814445092069, 0.19564827203562896, 0.7545079956121755]
  },
  'pinky-finger-phalanx-distal': {
    position: [0.004511065781116486, -0.06341385841369629, 0.03370495140552521],
    quaternion: [0.4009713821417304, -0.06275414592364197, 0.20712684434689518, 0.8901586028476283]
  },
  'pinky-finger-tip': {
    position: [0.002585865557193756, -0.046552300453186035, 0.01977252960205078],
    quaternion: [0.4009713821417304, -0.06275414592364197, 0.20712684434689518, 0.8901586028476283]
  }
}
