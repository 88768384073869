import { useState, useEffect } from "react";
import PropertyService from "services/property.service";
import { Property } from "types";

export default function useSingleProperty(propertyId: string) {
  const propertyService = new PropertyService();

  const [property, setProperty] = useState<Property>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchSingleProperty = async () => {
    try {
      const data: Property | any = await propertyService.fetchSingleProperty(
        propertyId
      );

      if (data?.statusCode === 400) {
        throw new Error(data?.message);
      }

      setProperty(data);
    } catch (error) {
      setError("Error fetching properties");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSingleProperty();
  }, []);

  return {
    property,
    loading,
    error,
  };
}
