import { useGLTF, useBoxProjectedEnv, CubeCamera } from "@react-three/drei";
import { useControls } from "leva";
import * as THREE from "three";

function Floor() {
  const { nodes, materials } = useGLTF("/models/court.glb") as any;
  const { up, scale, ...config } = useControls({
    up: { value: -0.5, min: -10, max: 10 },
    scale: { value: 27, min: 0, max: 50 },
    roughness: { value: 0.06, min: 0, max: 0.15, step: 0.001 },
    envMapIntensity: { value: 1, min: 0, max: 5 },
  });
  const projection = useBoxProjectedEnv([0, up, 0], [scale, scale, scale]);

  return (
    <CubeCamera
      frames={1}
      position={[0, 0.5, 0]}
      rotation={[0, 0, 0]}
      resolution={2048}
      near={1}
      far={1000}
    >
      {(texture) => (
        <mesh
          receiveShadow
          position={[-13.68, -0.467, 17.52]}
          scale={0.02}
          geometry={nodes.GymFloor_ParquetShader_0.geometry}
          dispose={null}
        >
          <meshStandardMaterial
            map={materials.ParquetShader.map}
            normalMap={materials.ParquetShader.normalMap}
            normalMap-encoding={THREE["LinearEncoding"]}
            envMap={texture}
            metalness={0.0}
            normalScale={[0.25, -0.25] as any}
            color="#aaa"
            {...(projection as any)}
            {...config}
          />
        </mesh>
      )}
    </CubeCamera>
  );
}

export default Floor;
