import React from 'react';
import { Html } from "@react-three/drei";
import useCommonMethods from "hooks/useCommonMethods";
import { DataPointType } from "utils/types";
import usePlayerWithDev from "hooks/usePlayerWithDev";
import { useFrame } from "@react-three/fiber";
import { ArrowHelper, Vector3 } from "three";

type Props = {
  index: number;
  state: DataPointType;
  currentDatapointIndex: number;
  userGettingAwayRef: React.MutableRefObject<boolean>;
};

function DataPoint({ state, userGettingAwayRef }: Props) {
  const { arrayToVector3 } = useCommonMethods();
  const { player } = usePlayerWithDev();
  const arrowRef = React.useRef<ArrowHelper>();
  const planeRef = React.useRef<any>();
  const directionFloorStripPanelRef = React.useRef<any>();
  const cylinderRef = React.useRef<any>();

  useFrame(({ clock }) => {
    if (player && arrowRef.current && planeRef.current) {
      const playerPosition = new Vector3(player.position.x, player.position.y, player.position.z);
      const datapointPosition = arrayToVector3(state.position);
      const direction = new Vector3().subVectors(datapointPosition, playerPosition).normalize();
      const distance = playerPosition.distanceTo(datapointPosition);

      arrowRef.current.setDirection(direction);
      arrowRef.current.setLength(distance);
      arrowRef.current.position.copy(playerPosition);
    }

    // Control visibility and pulsating effect for the cylinder
    if (cylinderRef.current) {
      cylinderRef.current.visible = userGettingAwayRef.current;
      if (userGettingAwayRef.current) {
        const scale = 0.5 + 0.2 * Math.sin(clock.getElapsedTime() * 3); // Pulsate by changing scale over time
        cylinderRef.current.scale.set(scale, 1, scale);
      }
    }

    console.log(userGettingAwayRef.current)
  });

  return (
    <group>
      {/* Sphere at the datapoint */}
      <mesh position={arrayToVector3(state.position)}>
        <sphereGeometry args={[1.25, 32, 32]} />
        <meshBasicMaterial color="blue" />
        <Html center style={{ color: '#78ff93', width: '120px' }}>
          <h1>{state.position.join(', ')}</h1>
        </Html>
      </mesh>

      {/* Arrow pointing from player to datapoint */}
      <arrowHelper ref={arrowRef} args={[new Vector3(1, 0, 0), new Vector3(), 0, 'red']} />

      {/* Vertical pulsating line from datapoint */}
      <mesh
        ref={cylinderRef}
        position={new Vector3(state.position[0], state.position[1] + 20, state.position[2])}
      >
        <cylinderGeometry args={[0.20, 0.20, 80, 8]} />
        <meshBasicMaterial color="orange" />
      </mesh>
    </group>
  );
}

export default DataPoint;
